import { createLocationObject } from "./helper";
const routes = require('../routes/routes.json');


export function getDirections(route) {
  return (routes[route].route.map(elem => {
    return createLocationObject(
      elem.from.latLng,
      elem.from.title,
      elem.to.latLng,
      elem.to.title,
      elem.strokeColor,
      elem.text
    );
  }));
}
