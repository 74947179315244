import React, {Component} from 'react';
import {render} from 'react-dom';
import logo from './Wakapapa_logo_bike__1.png';
import './App.css';
import { Home } from "./components/Home";
import { Navigation } from "./components/Navigation";

import video from './HJEMMESIDE_VERSION.mp4';

var component;


class App extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
             onlyVideo: false
         };
         component = this;
    }

    componentWillMount(){
        window.addEventListener("orientationchange", function() {
            component.setState({onlyVideo:!component.state.onlyVideo});
        }, false);
    }
  render() {
      return (
        <div className="App">
        {
            !this.state.onlyVideo ?
                <div>
                <div className="video_contain">
                    <video controls autoPlay loop id="myVideo" muted>
                      <source src={video} type="video/mp4"></source>
                      Your browser does not support the video tag.
                    </video>
                </div>
                  <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" width="150" height="150"/>
                  </header>
                  <Navigation/>
                </div>
            :
            <div id="video-wrap" style={{width:'100%',height:'100%',backgroundColor:'black'}}>
                <video id="vid" controls loop style={{width:'100%'}}>
                  <source src={video} type="video/mp4"></source>
                  Your browser does not support the video tag.
                </video>
            </div>
        }
        </div>

      );
    }
}

export default App;
