import React from "react";
import $ from 'jquery';
import ReactCountryFlag from "react-country-flag";
import '../css/navigation.css';
import bike from '../IMG_2154.jpg';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import {MdDone } from 'react-icons/md';
import { MdFace } from 'react-icons/md';
import { MdFeedback } from 'react-icons/md';
import { MdDirectionsBike } from 'react-icons/md';
import { MdInsertEmoticon } from 'react-icons/md';
import { MdDirections } from 'react-icons/md';
import { MdGroup, MdAlarmOn, MdSwapVert, MdAccessibility, MdSettings, MdLock, MdCreate, MdReportProblem, MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import Directions from "./MyMapComponent";

const translation = require('../languages/translation.json');
var component;

// function Map(){
//     return (
//         <GoogleMap defaultZoom = {11} defaultCenter={{lat:45,lng:-75}}>
//         <BicyclingLayer/>
//         </GoogleMap>
//     );
// }
//
// const WrappedMap = withScriptjs(withGoogleMap(Map))

export class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
             language : "gb",
             visibleBike: false,
             visibleUs: false,
             visibleYou:false,
             visibleRoutes:false,
             width:0,
             height:0,
             route: "snapshotOfCopenhagen",
             videoPlay: true,
             quote: true
         };

         component = this;
    }

    showBike() {
        this.setState({ visibleBike: true, visibleUs: false, visibleYou: false, visibleRoutes: false});

    }

    hideBike() {
        this.setState({ visibleBike: false });
    }
    showUs() {
        this.setState({ visibleUs: true, visibleBike: false, visibleYou: false, visibleRoutes: false});
    }

    hideUs() {
        this.setState({ visibleUs: false });
    }
    showYou() {
        this.setState({ visibleYou: true, visibleUs: false, visibleBike: false, visibleRoutes: false});
    }

    hideYou() {
        this.setState({ visibleYou: false });
    }
    showRoutes() {
        this.setState({ visibleRoutes: true, visibleYou: false, visibleUs: false, visibleBike: false});
    }

    hideRoutes() {
        this.setState({ visibleRoutes: false });
    }

    updateDimensions() {
        component.setState({width: $(window).width(), height: $(window).height()});
    }
    componentWillMount() {
        this.updateDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);

    }

    componentDidMount(props) {
        //assign video element to variable vid
        var vid = document.getElementById("myVideo");
        vid.play();

        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        // We listen to the resize event
        window.addEventListener('resize', () => {
          // We execute the same script as before
          this.updateDimensions();
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        });

        function DropDown(el) {
            this.dd = el;
            this.initEvents();
        }

        function changeLanguage(newLanguage){
            component.setState({language:newLanguage});
        }

        DropDown.prototype = {
            initEvents : function() {
                var obj = this;
                console.log(obj.dd);
                obj.dd.on('click', function(event){
                    $(this).toggleClass('active');
                    var languageList = obj.dd.children()[1];
                    for(var i = 0; i < $(languageList).children().length; i++){
                        var element = $(languageList).children()[i];
                        $(element).unbind("click");
                        $(element).on('click', function(event){
                            changeLanguage(this.id);
                            event.stopPropagation();
                            $('.wrapper-dropdown-5').removeClass('active');
                        });
                    }

                    event.stopPropagation();
                });
            }
        }

        $(function() {

            var dd = new DropDown( $('#dd') );

            $(document).click(function() {
                // all dropdowns
                $('.wrapper-dropdown-5').removeClass('active');

            });

        });

    }

    render(){

        return (
            <div>

                <Rodal customStyles={{padding:'50px'}} height={this.state.height} width={this.state.width} visible={this.state.visibleBike} onClose={this.hideBike.bind(this)} animation="slideUp">
                    <div id="bikeContainer">
                    <img src={bike} className="bike" alt="bike" width="840" height="600"/>
                        <div className="bikeDescription">
                            <h1>
                                {translation[this.state.language].overlineBike}
                            </h1>
                            <ul >
                                <li className="tl-itemBike">
                                <div className="tl-iconBike"><MdDirectionsBike size={25} color="white"/></div>
                                <div className="tl-contentBike">
                                  <p>{translation[this.state.language].bikePointOne}</p>
                                 </div>
                                </li>
                                <li className="tl-itemBike tl-right">
                                 <div className="tl-iconBike"><MdAlarmOn size={25} color="white"/></div>
                                 <div className="tl-contentBike">
                                   <p>{translation[this.state.language].bikePointTwo}</p>
                                  </div>
                                </li>

                                <li className="tl-itemBike tl-right">
                                <div className="tl-iconBike"><MdSwapVert size={25} color="white"/></div>
                                <div className="tl-contentBike">
                                  <p> {translation[this.state.language].bikePointThree} </p><ul><li>{translation[this.state.language].bikePointFour}<br/><br/></li><li> {translation[this.state.language].bikePointFive} </li></ul>
                                 </div>
                                </li>

                                <li className="tl-itemBike tl-right">
                                <div className="tl-iconBike"><MdAccessibility size={25} color="white"/></div>
                                <div className="tl-contentBike">
                                  <p> {translation[this.state.language].bikePointSix} </p>
                                 </div>
                                </li>

                                <li className="tl-itemBike tl-right">
                                <div className="tl-iconBike"><MdSettings size={25} color="white"/></div>
                                <div className="tl-contentBike">
                                  <p> {translation[this.state.language].bikePointSeven} </p>
                                 </div>
                                </li>

                                <li className="tl-itemBike tl-right">
                                <div className="tl-iconBike"><MdLock size={25} color="white"/></div>
                                <div className="tl-contentBike">
                                  <p> {translation[this.state.language].bikePointEight} </p>
                                 </div>
                                </li>

                                <li className="tl-itemBike tl-right">
                                <div className="tl-iconBike"><MdCreate size={25} color="white"/></div>
                                <div className="tl-contentBike">
                                  <p> {translation[this.state.language].bikePointNine} </p>
                                 </div>
                                </li>

                                <li className="tl-itemBike tl-right">
                                <div className="tl-iconBike"><MdReportProblem size={25} color="white"/></div>
                                <div className="tl-contentBike">
                                  <p> {translation[this.state.language].bikePointTen} </p>
                                 </div>
                                </li>

                            </ul>
                        </div>


                    </div>
                </Rodal>
                <Rodal customStyles={{padding:'0px'}} height={this.state.height} width={this.state.width} visible={this.state.visibleUs} onClose={this.hideUs.bind(this)} animation="slideUp">
                    <div className="modalContent">
                        <h1 className="usOverline">{translation[this.state.language].usOverline}</h1>
                        <ul  className="timeline">
                            <li className="tl-item">
                            <div className="tl-icon"><MdFace size={25} color="white"/></div>
                            <div className="tl-time">{translation[this.state.language].experience}</div>
                            <div className="tl-content">
                              <p>{translation[this.state.language].experienceContent}</p>
                             </div>
                            </li>
                            <li className="tl-item tl-right">
                             <div className="tl-icon"><MdDone size={25} color="white"/></div>
                             <div className="tl-time">{translation[this.state.language].goodServices}</div>
                             <div className="tl-content">
                               <p>{translation[this.state.language].goodServicesContent}</p>
                              </div>
                            </li>

                            <li className="tl-item">
                            <div className="tl-icon"><MdFeedback size={25} color="white"/></div>
                            <div className="tl-time">{translation[this.state.language].feedback}</div>
                            <div className="tl-content">
                              <p> {translation[this.state.language].feedbackContent} </p>
                             </div>
                            </li>

                        </ul>

                    </div>
                </Rodal>
                <Rodal customStyles={{padding:'100px',overflow:'scroll'}} height={this.state.height} width={this.state.width} visible={this.state.visibleYou} onClose={this.hideYou.bind(this)} animation="slideUp">
                    <div>
                        {
                            this.state.quote ?
                            <div className="quoteContainer">
                                <div id="quote">
                                    <p id="quoteContent">{translation[this.state.language].quoteContent1}</p>
                                    <p id="quoteAuthor">{translation[this.state.language].quoteAuthor1}</p>
                                </div>
                                <ul style={{display: 'inline-block',marginTop:'40px'}}>
                                    <button class="leftButton" onClick={()=>{this.setState({quote:!this.state.quote})}}><MdKeyboardArrowLeft size={40} color="black"/> </button>
                                    <button class="rightButton" onClick={()=>{this.setState({quote:!this.state.quote})}}><MdKeyboardArrowRight size={40} color="black" /></button>
                                </ul>
                            </div>
                            :
                            <div>
                                <div className="quoteContainer">
                                    <div id="quote">
                                        <p id="quoteContent">{translation[this.state.language].quoteContent2}</p>
                                        <p id="quoteAuthor">{translation[this.state.language].quoteAuthor2}</p>
                                    </div>
                                </div>
                                <ul style={{display: 'inline-block', marginTop:'40px'}}>
                                    <button class="leftButton" onClick={()=>{this.setState({quote:!this.state.quote})}}><MdKeyboardArrowLeft size={40} color="black"/></button>
                                    <button class="rightButton" onClick={()=>{this.setState({quote:!this.state.quote})}}><MdKeyboardArrowRight size={40} color="black" /></button>
                                </ul>
                            </div>
                        }
                    </div>
                </Rodal>

                <Rodal customStyles={{padding:'50px'}} height={this.state.height} width={this.state.width} visible={this.state.visibleRoutes} onClose={this.hideRoutes.bind(this)} animation="slideUp">
                    <ul id="routesBar">
                        <li><button onClick={()=>{this.setState({route:"snapshotOfCopenhagen"})}}>Snapshot of Copenhagen</button></li>
                    </ul>
                    <div>
                        <Directions route={this.state.route}/>
                    </div>
                </Rodal>
                <div id="flags">
                    <div id="dd" className="wrapper-dropdown-5" tabIndex="1">
                    <a href="#">
                        <ReactCountryFlag styleProps={{width: '20px', height: '20px',marginLeft:'-20px',marginTop:'-5px'}} code={this.state.language} svg /></a>
                            <ul className="dropdown">
                                <li id="dk"><a href="#">
                                <ReactCountryFlag
                                    styleProps={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft:'0px',
                                        marginTop:'-5px'
                                    }}
                                    code="dk"
                                    svg
                                /></a></li>
                                <li id="gb"><a href="#">
                                <ReactCountryFlag
                                    styleProps={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft:'0px',
                                        marginTop:'-5px'
                                    }}
                                    code="gb"
                                    svg
                                /></a></li>
                                <li id="de"><a href="#"><ReactCountryFlag
                                    styleProps={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft:'0px',
                                        marginTop:'-5px'
                                    }}
                                    code="de"
                                    svg
                                /></a></li>
                                <li id="fr"><a href="#"><ReactCountryFlag
                                    styleProps={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft:'0px',
                                        marginTop:'-5px'
                                    }}
                                    code="fr"
                                    svg
                                /></a></li>
                                <li id="es"><a href="#"><ReactCountryFlag
                                    styleProps={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft:'0px',
                                        marginTop:'-5px'
                                    }}
                                    code="es"
                                    svg
                                /></a></li>
                                <li id="se"><a href="#"><ReactCountryFlag
                                    styleProps={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft:'0px',
                                        marginTop:'-5px'
                                    }}
                                    code="se"
                                    svg
                                /></a></li>
                            </ul>
                    </div>
                </div>

                <nav className="navbar navbar-default">
                    <div className="flex-grid-thirds">
                        <ul id="us" className="col"  onClick={this.showUs.bind(this)}>
                            <li><MdInsertEmoticon size={25} color="black"/></li><li><p>{translation[this.state.language].nav1}</p></li>
                        </ul>
                        <ul id="bike" className="col"  onClick={this.showBike.bind(this)}>
                            <li><MdDirectionsBike size={25} color="black"/></li><li><p>{translation[this.state.language].nav2}</p></li>
                        </ul>
                        <ul id="you" className="col"  onClick={this.showYou.bind(this)}>
                            <li><MdGroup size={25} color="black"/></li><li><p>{translation[this.state.language].nav3}</p></li>
                        </ul>
                        <ul id="routes" className="col"  onClick={this.showRoutes.bind(this)}>
                            <li><MdDirections size={25} color="black"/></li><li><p>{translation[this.state.language].nav4}</p></li>
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}
