import React, { Component } from "react";
import { compose, withProps } from "recompose";
import DirectionRenderComponent from "./DirectionRenderComponent";
import { G_API_URL } from "../utility/constants";
import DummyLocations from "../utility/dummyLocations";
import { getDirections } from "../utility/dummyLocations";
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

const routes = require('../routes/routes.json');

class Directions extends Component {
  state = {
    defaultZoom: 14,
    map: null,
    center: {
      lat: routes[this.props.route].center[0],
      lng: routes[this.props.route].center[1]
    }
  };
  render() {
    return (
      <GoogleMap
        defaultZoom={this.state.defaultZoom}
        center={this.state.center}
        defaultCenter={new window.google.maps.LatLng(23.21632, 72.641219)}
      >
        {getDirections(this.props.route).map((elem, index) => {

          return (
            <DirectionRenderComponent
              key={index}
              index={index + 1}
              strokeColor={elem.strokeColor}
              from={elem.from}
              to={elem.to}
              infoTitle={elem.toTitle}
              infoText={elem.text}
            />
          );
        })}
      </GoogleMap>
    );
  }
}

export default compose(
  withProps({
    googleMapURL: G_API_URL,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `calc(100vh - 300px)` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(Directions);
